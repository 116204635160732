<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-07-27 14:37:03
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-02-19 11:36:46
 * @FilePath: \official_website\src\views\componets\Footer.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!--  -->
<template>
  <div id="footer">
    <div class="friendly-links">
      <div class="logo">
        <div class="logo-box">
          <img src="@/assets/image/home/logo.png" alt="" />
        </div>
        <div class="adress-box">
          <div class="adress">
            <i class="el-icon-location"></i>
            <span>地址：长沙高新开发区麓天路28号金瑞麓谷科技园D1栋5~6楼</span>
          </div>
          <div class="telphone">
            <i class="el-icon-phone"></i>
            <div class="tel-box">
              <div class="left">电话：</div>
              <div class="right">
                <p>0731-88737348（总机）</p>
                <p>18873193239（商务咨询）</p>
              </div>
            </div>
          </div>
          <div class="email"></div>
        </div>
      </div>
      <div class="links-box">
        <ul class="links">
          <div class="title">解决方案</div>
          <li class="link-item">
            <router-link to="/product/tbs">宫颈癌筛查解决方案</router-link>
          </li>
          <li class="link-item">
            <router-link to="/product/dna">DNA倍体癌症早筛解决方案</router-link>
          </li>
          <li class="link-item">
            <router-link to="/product/telepathology"
              >远程病理诊断解决方案</router-link
            >
          </li>
          <li class="link-item">
            <router-link to="/product/blood"
              >血液细胞形态诊断解决方案</router-link
            >
          </li>
        </ul>
        <ul class="links">
          <div class="title">产品</div>
          <li class="link-item">
            <router-link to="/product">试剂</router-link>
          </li>
          <li class="link-item">
            <router-link to="/product">数字切片扫描仪</router-link>
          </li>
          <li class="link-item">
            <router-link to="/product">液基细胞学前处理</router-link>
          </li>
          <!-- <li class="link-item">血液细胞形态</li> -->
          <li class="link-item">
            <router-link to="/product">人工智能辅助诊断</router-link>
          </li>
        </ul>
        <ul class="links">
          <div class="title">关于品信</div>
          <li class="link-item">
            <router-link to="/about">公司介绍</router-link>
          </li>
          <li class="link-item">
            <router-link to="/contact">人才招募</router-link>
          </li>
          <!-- <li class="link-item">联系方式: 电话、地址、公众号、视频号</li> -->
        </ul>

        <div class="links barcode">
          <div class="title">联系我们</div>

          <div class="code-box">
            <div class="public">
              <img src="@/assets/image/home/barcode.jpg" alt="" />
              <div class="text">微信公众号</div>
            </div>
            <div class="business">
              <img src="@/assets/image/home/business.jpg" alt="" />
              <div class="text">商务号</div>
            </div>
          </div>
        </div>
        <!-- <ul class="links">
          <div class="title">加入我们</div>
          <li class="link-item">RETURN & EXCHANGES</li>
          <li class="link-item">SHIPPING & DELIVERY</li>
          <li class="link-item">PRIVACY POLICY</li>
          <li class="link-item">REFUND POLICY</li>
          <li class="link-item">TERMS OF SERVICES</li>
        </ul> -->
      </div>
    </div>
    <div class="copyright">
      <span>隐私声明</span>
      <span>© Copyright 2023 | Flag Stars Inc. All Rights Reserved.</span>
    </div>
    <div class="footer-bottom reveal-bottom">
      <span>
        版权所有品信生物工程 © 2015-2022. 备案号:
        <a href="https://beian.miit.gov.cn/" target="_blank"
          >湘ICP备15003521号</a
        >
        <a href="//tongji.baidu.com/web/26696728/overview/index" target="_blank"
          >百度统计</a>
      </span>
    </div>
  </div>
</template>
    
<script>
export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  watch: {},
  methods: {},
};
</script>
    
<style lang="scss">
#footer {
  .friendly-links {
    display: flex;
    height: 18.75rem;
    .logo {
      width: 30%;
      .logo-box {
        height: 3rem;
        margin-bottom: 4rem;
        img {
          height: 100%;
        }
      }
      .adress-box {
        font-size: 0.8rem;
        font-family: "OPlusSans3-Regular";
        color: #666666;
        .adress {
          display: flex;
          align-items: center;
          margin-bottom: 0.5rem;
          i {
            margin-right: 0.5rem;
          }
        }
        .telphone {
          display: flex;
          i {
            padding-top: 2px;
            margin-right: 0.5rem;
          }
          .tel-box {
            display: flex;
            .left {
            }
            .right {
              p {
                margin-bottom: 0.3rem;
              }
            }
          }
        }
      }
    }
    .links-box {
      flex: 1;
      display: flex;
      .links {
        width: 25%;
        .title {
          font-size: 1.2rem;
          margin-bottom: 2.2rem;
          font-family: "SourceHanSansSC-Medium";
        }
        .link-item {
          margin-bottom: 1.25rem;
          font-size: 1rem;
          font-family: "OPlusSans3-Regular";
          color: #666666;
          &:hover a {
            color: #0b847b !important;
            font-weight: bold;
            transition: all 0.5s ease;
          }
        }
      }
      .barcode {
        .code-box {
          display: flex;
          .public {
            width: 45%;
            margin-right: 10%;
            img {
              width: 100%;
              border: 1px solid #0b847b;
            }
            .text {
              text-align: center;
              font-size: 0.8rem;
              font-family: "OPlusSans3-Regular";
              color: #666666;
            }
          }
          .business {
            width: 45%;
            img {
              width: 100%;
              border: 1px solid #0b847b;
            }
            .text {
              text-align: center;
              font-size: 0.8rem;
              font-family: "OPlusSans3-Regular";
              color: #666666;
            }
          }
        }
      }
    }
  }
  .copyright {
    display: flex;
    padding-top: 1.25rem;
    justify-content: space-between;
    font-size: 0.6rem;
    font-family: "OPlusSans3-Regular";
    color: #666666;
  }
  .footer-bottom {
    opacity: 0.7;
    line-height: 2;
    text-align: center;
    font-size: 0.875rem;
    color: #4d4d4d;
    a {
      text-decoration: underline;
      color: #4d4d4d;
      transition: all 0.5s;
      margin-right: 0.3125rem;
      &:hover {
        color: #0b847b;
        transition: all 0.5s;
      }
    }
  }
}
</style>